<template>
  <v-container ref="mainContainer" color="#f1f1f1" class="pa-3" :class="fHeight">
    <v-card
      class="mx-auto change-font"
      mix-width="360"
      max-width="560"
      elevation="0"
      outlined
      tile
      color="#f1f1f1"
    >
      <v-card-text class="pa-0">
        <div class="title-size font-weight-bold">위대한 폭발: 코로나19</div>
        <div style="color: black; font-size: 19.2px">
          코로나 바이러스가 창궐한 지금 무슨 말을 하고 싶습니까? 여러분의 말을
          모아 한번에 폭발시켜드립니다. 재난감각 전시 @김희수아트센터 2020.12.10
          - 2021.1.10
        </div>
        <br />
        <div class="title-size font-weight-bold">
          The Great Explosion: Covid-19
        </div>
        <div style="color: black; font-size: 19.2px">
          What do you want to say right now in Covid-19 situations? We’ll
          collect your voices and explode them at once. Catastrophic Sensation
          Exhibiton @ KIM HEE SOO Art Center, Seoul 10th Dec 2020 - 10th Jan
          2020
        </div>
        <div></div>
        <div>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="name"
              label="가명 / Nickname"
              :rules="rules"
              hide-details="auto"
              color="black"
              class="form-input"
            ></v-text-field>
          </v-form>
        </div>
        <canvas id="audio_visual" height="30"></canvas>
        <v-btn
          v-if="!isRecDisabled"
          color="yellow"
          block
          @click="StartRecording()"
          class="black--text"
          style="color: black; font-size: 19.2px"
        >
          <v-icon>mdi-microphone</v-icon>Record
        </v-btn>
        <v-btn
          v-else
          color="grey"
          block
          class="black--text"
          style="color: black; font-size: 19.2px"
        >
          <v-icon>mdi-microphone</v-icon>{{ txtRec }}
        </v-btn>
        <div v-show="!isPlayBlockDisabled">
          <div style="width=330;height=50" id="waveform"></div>
          <v-btn
            :disabled="isPlayDisabled"
            v-if="!isPlay"
            color="#333333"
            block
            outlined
            @click="Play()"
            style="color: black; font-size: 19.2px"
          >
            <v-icon>mdi-play</v-icon>Play
          </v-btn>
          <v-btn
            v-else
            color="grey"
            block
            @click="StopPlay()"
            style="color: black; font-size: 19.2px"
          >
            <v-icon>mdi-stop</v-icon>Stop
          </v-btn>
        </div>
        <v-divider class="mx-4 my-2"></v-divider>
        <v-btn
          color="#333333"
          block
          outlined
          :disabled="saveBlob == null || !valid"
          @click="Save()"
          class="black--text"
          style="color: black; font-size: 19.2px"
        >
          <v-icon left>mdi-send</v-icon>Send
        </v-btn>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#F1F1F1"
            elevation="0"
            class=""
            @click="OpenCredit()"
            style="color: black; font-size: 19.2px; text-decoration: underline"
          >
            Credit
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Sending...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWarning" persistent max-width="290">
      <v-card class="change-font">
        <v-card-title class="headline">
          {{ warningTitle }}
        </v-card-title>
        <v-card-text style="color: black; font-size: 19.2px">{{
          warningText
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialogWarning = false">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCredit" min-width="360" max-width="560">
      <v-card class="change-font">
        <v-card-title> 위대한 폭발: 코로나19 </v-card-title>
        <v-card-subtitle>
          2020, 가변 사이즈, 인터렉티브 미디어 설치
        </v-card-subtitle>
        <v-card-text class="mb-2" style="color: black; font-size: 19.2px">
          위대한 폭발 프로젝트는 사람들의 목소리를 재료로 사용한 폭발
          시뮬레이션이다. 폭발에서 날아가는 수많은 입자들의 에너지와 경로를
          사람들의 목소리에 맵핑하여 응축시키고 폭발시킨다. 동시에 재생되는
          알아들을 수 없는 사람들의 목소리, 버겁게 돌아가는 오버드라이브된
          컴퓨터, 싱크와 혼돈을 반복하는 영상을 통해서 시뮬레이션된 재난과
          실재하는 코로나 상황을 생각해본다.
        </v-card-text>
        <v-card-text style="color: black; font-size: 19.2px">
          예술가: <a href="https://hojunsong.com">송호준</a><br />과학자:
          임세혁<br />웹: <a href="https://hochulsong.com">송호철</a
          ><br />큐레이터: 박지인, 문규림
        </v-card-text>
        <v-card-title> The Great Explosion: Covid-19 </v-card-title>
        <v-card-subtitle>
          2020, Variable Size, Interactive Media Installation
        </v-card-subtitle>
        <v-card-text class="mb-2" style="color: black; font-size: 19.2px">
          The Great Explosion project collects voices from particpants as
          materials for the explosion simulation. Voices are mapped to exploded
          fragments’ energies and trajectories and are condensed to explode. A
          noise like people’s voices played at once, a higly loaded, overdrived
          computer, and a video of sync and chaos give us chances to think
          between a simulated disaster and a existing Covid 19 situation.
        </v-card-text>
        <v-card-text style="color: black; font-size: 19.2px">
          Artist: <a href="https://hojunsong.com">Hojun Song</a><br />Scientist:
          Sehyuk Yim<br />Web: <a href="https://hochulsong.com">Hochul Song</a
          ><br />Curators: Jiinn Park, Gyulim Moon
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialogCredit = false">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import firebase from "firebase";
import axios from "axios";
export default {
  name: "HelloWorld",
  created() {
    window.addEventListener("resize", this.OnResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.OnResize);
  },
  OnResize() {
    var canvas = document.getElementById("audio_visual");
    var heightRatio = 1.5;
    canvas.height = canvas.width * heightRatio;
    let ctx = canvas.getContext("2d");

    canvas.width = canvas.width + "px";
    canvas.height = canvas.height + "px";

    window.devicePixelRatio = 2;
    var scale = window.devicePixelRatio;

    canvas.width = Math.floor(size * scale);
    canvas.height = Math.floor(size * scale);

    //CSS pixels for coordinate systems
    ctx.scale(scale, scale);
    ctx.font = "10px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    var x = size / 2;
    var y = size / 2;
  },
  mounted() {
    if(!this.isMobile()){
      this.fHeight = "fill-height";
    }
    this.initCanvas();
  },
  methods: {
    OpenCredit() {
      this.dialogCredit = true;
      firebase.analytics().logEvent("OpenCredit");
    },
    initCanvas() {
      let canvas = document.getElementById("audio_visual");
      let ctx = canvas.getContext("2d");
      ctx.fillStyle = "#F1F1F1";
      ctx.strokeStyle = "#0";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    },
    onWindowLoad() {
      if (window.webkitAudioContext) {
        this.aContext = new window.webkitAudioContext();
      } else {
        this.aContext = new window.AudioContext();
      }
    },
    async StartRecording() {
      firebase.analytics().logEvent("StartRecording");
      this.aSrc = "";
      this.StopPlay();
      this.saveBlob = null;
      this.isPlayDisabled = true;
      this.isPlayBlockDisabled = true;
      if (this.aContext == null) {
        this.onWindowLoad();
      }

      this.isRecDisabled = true;
      try {
        this.gumStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.intervalIdx = setInterval(this.Stop, 5000);
        var start = Date.now();
        var audioContent = this.aContext;
        var audioStream = audioContent.createMediaStreamSource(this.gumStream);
        var analyser = audioContent.createAnalyser();
        audioStream.connect(analyser);
        analyser.fftSize = 2048;

        var frequencyArray = new Uint8Array(analyser.frequencyBinCount);

        let canvas = document.getElementById("audio_visual");
        let ctx = canvas.getContext("2d");
        ctx.lineWidth = 2;
        ctx.fillStyle = "#F1F1F1";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        var elapsed = 0;
        var o = this;
        var lastX = 0;
        var lastY = 0;
        var bufferLength = analyser.frequencyBinCount;
        ctx.moveTo(0, canvas.height);

        var doDraw = () => {
          var current = Date.now();
          elapsed += current - start;
          this.txtRec = (elapsed / 1000).toFixed(1) + "/5초";
          start = current;

          if (o.intervalIdx == null) return;
          requestAnimationFrame(doDraw);
          analyser.getByteTimeDomainData(frequencyArray);

          ctx.beginPath();
          var min = 1000;
          var max = -1000;
          for (var i = 0; i < bufferLength; i++) {
            if (min > frequencyArray[i]) {
              min = frequencyArray[i];
            }

            if (max < frequencyArray[i]) {
              max = frequencyArray[i];
            }
          }
          let x = (canvas.width * elapsed) / 5010;
          var v = min / 128.0;
          var y = (v * canvas.height) / 2;
          v = max / 128.0;
          var y2 = (v * canvas.height) / 2;
          ctx.lineTo(x, y);

          ctx.lineTo(x, y2);

          //ctx.lineTo(canvas.width, canvas.height / 2);
          ctx.stroke();

          /*analyser.getByteFrequencyData(frequencyArray);//getByteTimeDomainData

          let space = (canvas.width * elapsed) / 5010;
          lastX = Math.floor(lastX);
          for (var i = lastX; i < Math.ceil(space) - lastX; i++) {
            ctx.beginPath();
            ctx.moveTo(i, canvas.height); //x,y
            ctx.lineTo(i, canvas.height - lastY); //x,y
            ctx.stroke();
          }
          lastX = Math.floor(space);
          var vals = 0;
          for (var i = 0; i < 255; i++) {
            vals += frequencyArray[i];
          }
          vals = (vals / 555) * 0.5;
          lastY = vals;

          ctx.beginPath();
          ctx.moveTo(space, canvas.height); //x,y
          ctx.lineTo(space, canvas.height - vals); //x,y
          ctx.stroke();*/
        };
        doDraw();

        this.rec = new Recorder(audioStream, {
          numChannels: 1,
        });

        this.rec.record();
      } catch (e) {
        firebase.analytics().logEvent("StartRecordingError");
        this.isPlayBlockDisabled = true;
        clearInterval(this.intervalIdx);
        this.intervalIdx = null;
        this.isRecDisabled = false;
        this.warningTitle = "Warning";
        this.warningText =
          "아이폰은 Safari를 이용해 주세요. Mic를 허용해 주세요.";
        this.dialogWarning = true;
        console.log(e);
      }
    },
    Save() {
      firebase.analytics().logEvent("Save");
      if (!this.$refs.form.validate()) return;
      firebase.analytics().logEvent("SaveFull");
      this.dialogLoading = true;
      this.saveName =
        encodeURI(this.name) + "_" + new Date().getTime().toString() + ".wav";
      console.log(this.saveName);
      //private server
      let URL = "https://hojunsong.jp.ngrok.io/upload";
      let data = new FormData();
      data.append("sampleFile", this.saveBlob, this.saveName);

      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(URL, data, config)
        .then((response) => {
          console.log("response", response);
          firebase.analytics().logEvent("SavePrivateComplete");
        })
        .catch((error) => {
          console.log("error", error);
          firebase.analytics().logEvent("SavePrivateError");
        });

      //firebase
      const storageRef = firebase
        .storage()
        .ref(this.saveName)
        .put(this.saveBlob);
      var o = this;
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          //this.uploadValue =
          //(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          o.warningTitle = "파일전송 실패!";
          o.warningText = "File transfer failed. :(";
          o.dialogWarning = true;
          firebase.analytics().logEvent("SaveFailed");
        },
        () => {
          //this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            console.log("firebase file upload complete!");
            o.dialogLoading = false;
            o.warningTitle = "파일전송 완료!";
            o.warningText = "File transfer completed.";
            o.dialogWarning = true;
            o.saveBlob = null;
            o.initCanvas();
            o.name = "";
            firebase.analytics().logEvent("SaveComplete");
          });
        }
      );
    },
    StopPlay() {
      firebase.analytics().logEvent("StopPlay");
      this.isPlay = false;
      if (this.wavesurfer != null) this.wavesurfer.stop();
    },
    Play() {
      firebase.analytics().logEvent("Play");
      this.isPlay = true;
      this.wavesurfer.play();
    },
    Stop() {
      firebase.analytics().logEvent("StopRecording");
      this.isPlayBlockDisabled = false;
      clearInterval(this.intervalIdx);
      this.intervalIdx = null;
      this.isRecDisabled = false;
      this.rec.stop();
      this.gumStream.getAudioTracks()[0].stop();

      this.rec.exportWAV(this.createDownloadLink);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    async createDownloadLink(blob) {
      this.URL = window.URL || window.webkitURL;
      this.aSrc = this.URL.createObjectURL(blob);
      var url = this.aSrc;

      if (this.wavesurfer == null) {
        this.wavesurfer = WaveSurfer.create({
          container: "#waveform",
          waveColor: "#000000",
          progressColor: "#CCCCCC",
          interact: false,
          height: 50,
          cursorColor: "#CCCCCC",
        });
        this.wavesurfer.on("ready", () => {
          this.isPlayDisabled = false;
        });
        this.wavesurfer.on("finish", () => {
          this.isPlay = false;
        });
      }
      var arrayBuffer = await blob.arrayBuffer();
      this.aContext.decodeAudioData(arrayBuffer, (buffer) => {
        this.wavesurfer.loadDecodedBuffer(buffer);
      });

      this.saveBlob = blob;
      firebase.analytics().logEvent("createDownloadLink");
    },
  },
  data: () => ({
    URL: window.URL || window.webkitURL,
    gumStream: null,
    fHeight:"",
    rec: null,
    input: null,
    aContext: null,
    isRecDisabled: false,
    intervalIdx: null,
    processor: null,
    aSrc: "",
    media: null,
    audioElm: null,
    wavesurfer: null,
    isPlayDisabled: true,
    isPlayBlockDisabled: true,
    isPlay: false,
    saveBlob: null,
    saveName: null,
    txtRec: "Recording",
    rules: [
      (value) => !!value || "Required.",
      (value) =>
        (value && value.length >= 1 && value.length <= 9) ||
        "Please enter at least 1, up to 9 chatacters.",
    ],
    valid: true,
    isShowLoader: false,
    dialogLoading: false,
    dialogWarning: false,
    warningTitle: "",
    warningText: "",
    name: "",
    dialogCredit: false,
  }),
};
</script>
<style scoped>
canvas {
  width: 100%;
}
a:link {
  color: black;
}

a:visited {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: black;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: black;
  background-color: transparent;
  text-decoration: underline;
}
.change-font {
  font-family: "Times New Roman", serif;
  line-height: 1rem;
}
.title-size {
  font-size: 24px;
  color: black;
  margin-bottom: 8px;
}
.v-btn {
  text-transform: none !important;
}
.v-text-field.error--text,
.v-text-field .error--text {
  color: black !important;
  caret-color: black !important;
}
.form-input >>> .error--text {
  color: black !important;
  caret-color: black !important;
}
.form-input >>> input {
  color: black !important;
  caret-color: black !important;
}
.form-input >>> .v-input__slot::after {
  color: black !important;
  border-color: black !important;
}

.form-input >>> .v-input__slot::before {
  color: black !important;
  border-color: black !important;
}

.form-input >>> .label--text {
  color: black !important;
  border-color: black !important;
}
</style>
