<template>
  <v-app style="background:#F1F1F1">
    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>


<style>
html,
body {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
</style>