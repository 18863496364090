import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import firebase from "firebase";
import AudioVisual from 'vue-audio-visual'
import BlockUI from 'vue-blockui'
Vue.config.productionTip = false
Vue.use(AudioVisual)
Vue.use(BlockUI)
new Vue({
  vuetify,
  render: h => h(App),
  created(){
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    var firebaseConfig = {
      apiKey: "AIzaSyBPjWpvT7e7hgO5l-0FN-PhoeTKeCxmVoo",
      authDomain: "thegreatexplosion-covid19.firebaseapp.com",
      databaseURL: "https://thegreatexplosion-covid19.firebaseio.com",
      projectId: "thegreatexplosion-covid19",
      storageBucket: "thegreatexplosion-covid19.appspot.com",
      messagingSenderId: "289296638800",
      appId: "1:289296638800:web:8b8b4adbab37aa749b0255",
      measurementId: "G-6E1T9RB7X3"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    // alias
    Vue.prototype.$analytics = firebase.analytics();
  }
}).$mount('#app')
